<template>
  <v-dialog v-model="show" max-width="70%" hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" class="text-caption blue--text text-decoration-underline"> Configurat... </span>
    </template>
    <v-card>
      <v-card-title class="justify-center"> Kiosk Configuration </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>

      <v-row no-gutters>
        <v-col cols="6" class="pr-2">
          <v-card flat height="650" class="overflow-y-auto">
            <v-card-title class="subtitle-1">CMS Config</v-card-title>
            <v-card-text>
              <v-form ref="cmsConfigKioskForm" lazy-validation class="px-6">
                <v-text-field v-model="config.cmsLink" label="CMS Address URL (https://...)"
                  :rules="rules.cmsAddressRules" required outlined>
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title class="subtitle-1">Telepresence Config</v-card-title>
            <v-card-text>
              <v-form ref="cmsConfigKioskForm" lazy-validation class="px-6">
                <v-text-field v-model="config.videoLink" label="Telepresence URL (https://...)" outlined
                  :rules="rules.videoLinkRules" required>
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title class="subtitle-1">Avatar Config</v-card-title>
            <v-card-text>
              <v-form ref="avatarConfigKioskForm" lazy-validation class="px-6">
                <v-text-field v-model="config.sitepalId" label="Sitpal ID" :rules="rules.generalFieldRule" required
                  outlined>
                </v-text-field>
                <v-text-field v-model="config.avatarId" label="Avatar ID" :rules="rules.generalFieldRule" required
                  outlined>
                </v-text-field>
                <v-text-field label="Avatar Name" required v-model="config.avatarName" :rules="rules.generalFieldRule"
                  outlined>
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title class="subtitle-1">Chatbot Config</v-card-title>
            <v-card-text>
              <v-form ref="chatbotConfigKioskForm" lazy-validation class="px-6">
                <v-text-field v-model="config.host" label="Host Address (https://...)" :rules="rules.hostAddressRules"
                  required outlined>
                </v-text-field>
                <v-text-field v-model="config.route" label="Route (/knowledgebases/..../generateAnswer)"
                  :rules="rules.routeRules" required outlined>
                </v-text-field>
                <v-text-field v-model="config.endpointKey" label="Endpoint Key" :rules="rules.endpointRules"
                  @keyup.enter="saveKioskConfig" required outlined>
                </v-text-field>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6" class="pl-2">
          <v-card flat height="650" class="overflow-y-auto">
            <v-select :items="getClientConfig.courtInfo" v-model="config.locationCode" dense outlined
              label="Kiosk Location Code" class="mt-5 mx-16" item-text="alias"></v-select>
            <v-text-field label="Default Office Location (Add map location here for user to send Ex. Clerk's Office)"
              v-model="config.defaultLocation" class="mx-16"></v-text-field>
            <v-divider></v-divider>
            <v-card-title class="subtitle-1"> Select Language </v-card-title>
            <v-row no-gutters class="px-4">
              <v-col cols="12">
                <v-select v-model="config.languages" multiple :items="getClientConfig.languages" item-text="name"
                  item-value="code" class="mx-16" outlined dense label="Select Kiosk Languages"></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-title class="subtitle-1"> Select Modules </v-card-title>
            <v-row no-gutters class="px-4">
              <v-col cols="12">
                <v-select v-model="config.modules" multiple :items="getClientConfig.modules" item-text="name"
                  item-value="alias" class="mx-16" outlined dense label="Select Kiosk Modules"></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-title class="subtitle-1">Telepresence Feature</v-card-title>
            <v-switch
              v-model="config.telepresence"
              label="Telepresence"
              color="green"
              hide-details
              class="ml-5"
            ></v-switch>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="show = !show" width="175" outlined> <v-icon left>mdi-close</v-icon> Close </v-btn>
        <v-btn color="primary" @keyup.enter="saveKioskConfig" @click="saveKioskConfig" width="175">
          <v-icon left>mdi-content-save-cog</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "kioskConfig",
  props: {
    kioskObject: Object,
  },
  data() {
    return {
      show: false,
      loading: false,
      config: {
        videoLink: "",
        cmsLink: "",
        host: "",
        endpointKey: "",
        route: "",
        kId: null,
        sitepalId: null,
        avatarId: null,
        avatarName: null,
        locationCode: "",
        defaultLocation: "",
        courts: [],
        languages: [],
        modules: [],
        telepresence: false
      },
      rules: {
        cmsAddressRules: [(v) => !!v || "CMS address is required"],
        hostAddressRules: [(v) => !!v || "Host address is required"],
        routeRules: [(v) => !!v || "Route is required"],
        endpointRules: [(v) => !!v || "Endpoint key is required"],
        videoLinkRule: [(v) => !!v || "Telepresence URL is required"],
        generalFieldRule: [(v) => !!v || "Field cannot be left empty"],
      }
    };
  },
  computed: {
    ...mapGetters(['getClientConfig'])
  },
  methods: {
    saveKioskConfig() {
      if (
        this.$refs.cmsConfigKioskForm.validate() &&
        this.$refs.chatbotConfigKioskForm.validate() &&
        this.$refs.avatarConfigKioskForm
      ) {
        this.loading = true;
        console.log('New Config: ', this.config)
        this.$store.dispatch("saveKioskConfig", this.config).then(
          (response) => {
            this.loading = false;
            this.show = !this.show;
            this.$store.dispatch("showSnackBar", {
              text: response,
              color: "success",
            });
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
      }
    },
    test() {
      console.log('Config Updates: ', this.config.courts)
      this.config.courts.forEach(court => {
        if (court.load) {
          if (court.name === "District") {
            this.districtSelected = true
          } else {
            this.magistrateSelected = true
          }
        } else {
          if (court.name === "District") {
            this.districtSelected = false
          } else {
            this.magistrateSelected = false
          }
        }
      })
    }
  },
  mounted() {
    this.config = this.kioskObject.data();
    this.config.kId = this.kioskObject.id;
    console.log('Client Config : ', this.getClientConfig)
  },
};
</script>